.group {
    background: white;
    border: 1px solid #17299c;
}

#category {
    width: 400px;
    border: 1px solid rgba(128, 128, 128, 0.788);
    height: 60px;
}

#casc {
    border: 1px solid rgba(128, 128, 128, 0.788);
    height: 60px;
}

.ant-cascader-menu-item {
    font-size: x-small;
}

.ant-cascader-menus {
    z-index: 1301 !important;
}
.ant-cascader-picker {
    width: 100%;
}

#formContainer {
    box-sizing: border-box;
    width: 100%;
    height: 480px;
    margin-top: 20px;
    left: 0;
}

@media only screen and (max-width: 800px) {
    #formContainer {
        box-sizing: border-box;
        max-width: fit-content;
        height: 480px;
        margin-top: 20px;
    }
}

.ant-cascader {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
}
.ant-cascader-input.ant-input {
    position: static;
    width: 100%;
    padding-right: 24px;
    background-color: transparent !important;
    cursor: pointer;
}
.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
    position: relative;
}
.ant-cascader-picker {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    background-color: #fff;
    border-radius: 2px;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
}
.ant-cascader-picker-with-value .ant-cascader-picker-label {
    color: transparent;
}
.ant-cascader-picker-disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    cursor: not-allowed;
}
.ant-cascader-picker-disabled .ant-cascader-input {
    cursor: not-allowed;
}
.ant-cascader-picker:focus .ant-cascader-input {
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-cascader-picker-borderless .ant-cascader-input {
    border-color: transparent !important;
    box-shadow: none !important;
}
.ant-cascader-picker-show-search.ant-cascader-picker-focused {
    color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-picker-label {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 20px;
    margin-top: -10px;
    padding: 0 20px 0 12px;
    overflow: hidden;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.ant-cascader-picker-clear {
    position: absolute;
    top: 50%;
    right: 12px;
    z-index: 2;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 12px;
    background: #fff;
    cursor: pointer;
    opacity: 0;
    transition: color 0.3s ease, opacity 0.15s ease;
}
.ant-cascader-picker-clear:hover {
    color: rgba(0, 0, 0, 0.45);
}
.ant-cascader-picker:hover .ant-cascader-picker-clear {
    opacity: 1;
}
.ant-cascader-picker-arrow {
    position: absolute;
    top: 50%;
    right: 12px;
    z-index: 1;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 12px;
}
.ant-cascader-picker-label:hover
    + .ant-cascader-input:not(.ant-cascader-picker-disabled
        .ant-cascader-picker-label:hover
        + .ant-cascader-input) {
    border-right-width: 1px !important;
}
.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-small .ant-cascader-picker-arrow {
    right: 8px;
}
.ant-cascader-menus {
    position: absolute;
    z-index: 1050;
    font-size: 14px;
    white-space: nowrap;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-cascader-menus ul,
.ant-cascader-menus ol {
    margin: 0;
    list-style: none;
}
.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
    display: none;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut;
}
.ant-cascader-menu {
    display: inline-block;
    min-width: 111px;
    height: 180px;
    margin: 0;
    padding: 4px 0;
    overflow: auto;
    vertical-align: top;
    list-style: none;
    border-right: 1px solid #f0f0f0;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu:first-child {
    border-radius: 2px 0 0 2px;
}
.ant-cascader-menu:last-child {
    margin-right: -1px;
    border-right-color: transparent;
    border-radius: 0 2px 2px 0;
}
.ant-cascader-menu:only-child {
    border-radius: 2px;
}
.ant-cascader-menu-item {
    padding: 5px 12px;
    overflow: hidden;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
    background: #f5f5f5;
}
.ant-cascader-menu-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
    background: transparent;
}
.ant-cascader-menu-empty .ant-cascader-menu-item {
    color: rgba(0, 0, 0, 0.25);
    cursor: default;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    font-weight: 600;
}
.ant-cascader-menu-item-expand {
    position: relative;
    padding-right: 24px;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
    position: absolute;
    right: 12px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 10px;
}
.ant-input:focus {
    background-color: gray;
}
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand
    .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
    color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
    color: #ff4d4f;
}
.ant-cascader-picker-rtl .ant-cascader-input.ant-input {
    padding-right: 11px;
    padding-left: 24px;
    text-align: right;
}
.ant-cascader-picker-rtl {
    direction: rtl;
}
.ant-cascader-picker-rtl .ant-cascader-picker-label {
    padding: 0 12px 0 20px;
    text-align: right;
}
.ant-cascader-picker-rtl .ant-cascader-picker-clear {
    right: auto;
    left: 12px;
}
.ant-cascader-picker-rtl .ant-cascader-picker-arrow {
    right: auto;
    left: 12px;
}
.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-arrow {
    right: auto;
    left: 8px;
}
.ant-cascader-menu-rtl .ant-cascader-menu {
    direction: rtl;
    border-right: none;
    border-left: 1px solid #f0f0f0;
}
.ant-cascader-menu-rtl .ant-cascader-menu:first-child {
    border-radius: 0 2px 2px 0;
}
.ant-cascader-menu-rtl .ant-cascader-menu:last-child {
    margin-right: 0;
    margin-left: -1px;
    border-left-color: transparent;
    border-radius: 2px 0 0 2px;
}
.ant-cascader-menu-rtl .ant-cascader-menu:only-child {
    border-radius: 2px;
}
.ant-cascader-menu-rtl .ant-cascader-menu-item-expand {
    padding-right: 12px;
    padding-left: 24px;
}
.ant-cascader-menu-rtl
    .ant-cascader-menu-item-expand
    .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
    right: auto;
    left: 12px;
}
.ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
    transform: scaleY(-1);
}

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
    border-right-width: 1px;
    border-radius: 0;
}
.ant-input-group.ant-input-group-compact
    > .ant-select
    > .ant-select-selector:hover,
.ant-input-group.ant-input-group-compact
    > .ant-select-auto-complete
    .ant-input:hover,
.ant-input-group.ant-input-group-compact
    > .ant-cascader-picker
    .ant-input:hover,
.ant-input-group.ant-input-group-compact
    > .ant-input-group-wrapper
    .ant-input:hover {
    z-index: 1;
}
.ant-input-group.ant-input-group-compact
    > .ant-select
    > .ant-select-selector:focus,
.ant-input-group.ant-input-group-compact
    > .ant-select-auto-complete
    .ant-input:focus,
.ant-input-group.ant-input-group-compact
    > .ant-cascader-picker
    .ant-input:focus,
.ant-input-group.ant-input-group-compact
    > .ant-input-group-wrapper
    .ant-input:focus {
    z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact
    > .ant-select:first-child
    > .ant-select-selector,
.ant-input-group.ant-input-group-compact
    > .ant-select-auto-complete:first-child
    .ant-input,
.ant-input-group.ant-input-group-compact
    > .ant-cascader-picker:first-child
    .ant-input {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact
    > .ant-select:last-child
    > .ant-select-selector,
.ant-input-group.ant-input-group-compact
    > .ant-cascader-picker:last-child
    .ant-input,
.ant-input-group.ant-input-group-compact
    > .ant-cascader-picker-focused:last-child
    .ant-input {
    border-right-width: 1px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact
    > .ant-select:first-child
    > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact
    > .ant-select-auto-complete:first-child
    .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact
    > .ant-cascader-picker:first-child
    .ant-input {
    border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact
    > .ant-select:last-child
    > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact
    > .ant-select-auto-complete:last-child
    .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact
    > .ant-cascader-picker:last-child
    .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact
    > .ant-cascader-picker-focused:last-child
    .ant-input {
    border-left-width: 1px;
    border-radius: 2px 0 0 2px;
}

.ant-form-item .ant-input-group .ant-select,
.ant-form-item .ant-input-group .ant-cascader-picker {
    width: auto;
}
.ant-form-item-has-feedback .ant-cascader-picker-arrow {
    margin-right: 19px;
}
.ant-form-item-has-feedback .ant-cascader-picker-clear {
    right: 32px;
}
.ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
    margin-right: 0;
    margin-left: 19px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
    right: auto;
    left: 32px;
}
